<template>
  <div class="">
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col-lg-4">
          <div
            class="card ht-100v bd-0 text-left bd-1 bd-r"
            style="padding-top: 30vh"
          >
            <div class="table-responsive">
              <table class="table table-sm mg-b-20">
                <tbody>
                  <tr>
                    <th scope="row" class="text-right bg-gray-100 tx-color-03">
                      Улсын дугаар
                    </th>
                    <td class="text-left">{{ $route.params.plateNo }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-right bg-gray-100 tx-color-03">
                      Марк
                    </th>
                    <td class="text-left">{{ infVehicle.industry }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-right bg-gray-100 tx-color-03">
                      Модел
                    </th>
                    <td class="text-left">{{ infVehicle.model }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-right bg-gray-100 tx-color-03">
                      Өнгө
                    </th>
                    <td class="text-left">{{ infVehicle.color }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-8 pd-t-60">
          <div
            class="btn-group mg-y-10"
            role="group"
            aria-label="Basic example"
          >
            <button
              @click="loadList(1)"
              type="button"
              :class="`btn btn-${mode == 1 ? 'primary' : 'secondary'}`"
            >
              Төлөөгүй
            </button>
            <button
              @click="loadList(2)"
              type="button"
              :class="`btn btn-${mode == 2 ? 'primary' : 'secondary'}`"
            >
              Төлсөн
            </button>
            <button
              @click="loadInvoices()"
              type="button"
              :class="`btn btn-${mode == 3 ? 'primary' : 'secondary'}`"
            >
              И-баримт <sup>{{ paidInvoiceCount }}</sup>
            </button>
          </div>
          <div v-if="mode == 3">
            <table class="table">
              <thead>
                <tr>
                  <th>Огноо</th>
                  <th>Дүн</th>
                  <th>Үйлдэл</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l, i) in list">
                  <td>
                    {{ moment(l.paidDate).format("YYYY.MM.DD") }}
                  </td>
                  <td>{{ formatPrice(l.budget) }}</td>
                  <td class="text-right">
                    <button
                      @click="checkIsPaidNew(l)"
                      class="btn btn-xs btn-info"
                    >
                      eBarimt
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <table v-else class="table">
            <thead>
              <tr>
                <th>Төлөв</th>
                <th>Дүн</th>
                <th>Үйлдэл</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="
                  list.filter((x) => x.paid == (mode == 1 ? false : true))
                    .length == 0
                "
              >
                <td colspan="3">
                  <div class="alert alert-info mg-b-0" role="alert">
                    <h6 class="alert-heading">Мэдээлэл</h6>
                    <p>
                      <b>{{ $route.params.plateNo }}</b> дугаартай тээврийн
                      хэрэгсэлд
                      <b>{{ mode == 1 ? "төлөгдөөгүй" : "төлөгдсөн" }}</b>
                      хураамж алга байна.
                    </p>
                  </div>
                </td>
              </tr>
              <tr v-else v-for="(l, i) in list">
                <td>
                  <div class="text-muted tx-12">{{ l.gateName }}</div>
                  <div>
                    {{ moment(l.date).format("YYYY.MM.DD") }}
                    <sup>{{ moment(l.date).format("HH:mm") }}</sup>
                  </div>
                </td>
                <td>{{ formatPrice(l.budget) }}</td>
                <td class="text-right">
                  <button @click="showDetail(l)" class="btn btn-xs btn-info">
                    харах
                  </button>
                </td>
              </tr>
              <tr
                v-if="
                  mode == 1 && list.filter((x) => x.paid == false).length > 0
                "
              >
                <td colspan="3" class="text-right">
                  <button
                    @click="showInvoice()"
                    class="btn btn-xs btn-warning mg-r-5 btn-uppercase"
                  >
                    Нэхэмжлэх авах ({{ list.length }})
                  </button>
                  <button
                    @click="
                      mdlInvoiceBef = false;
                      showPayment();
                    "
                    class="btn btn-xs btn-success btn-uppercase"
                  >
                    Бүгдийг төлөх ({{ list.length }})
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      v-if="pay.modal"
      class="modal fade show"
      style="display: block; padding-right: 17px"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">
              {{ pay.mode == 1 ? "Төлбөр төлөх" : "Мэдээллэх" }}
            </h6>
            <button
              @click="pay.modal = false"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div
            class="modal-body"
            :style="`overflow-y: auto; height: ${
              pay.mode == 1 || pay.mode == 3 ? 'calc(100vh - 300px)' : 'auto'
            }`"
          >
            <!-- {{ pay.mode }} -->
            <div v-if="pay.mode == 1 && gateWays.length" class="text-left">
              <p class="tx-color-02 mg-y-20 font-weight-bold">
                Цахим хэтэвчээр төлөх
              </p>
              <ul class="list-group list-group tx-13 text-left">
                <li
                  @click="changePayMode(g.id)"
                  role="button"
                  class="list-group-item d-flex pd-x-20"
                  v-for="(g, i) in gateWays"
                >
                  <div class="avatar">
                    <img :src="g.logo" class="rounded-circle" alt="" />
                  </div>
                  <div class="pd-l-10">
                    <p class="tx-medium mg-b-0 font-weight-bold">
                      {{ g.name }}
                    </p>
                    <small class="tx-12 tx-color-03 mg-b-0">{{ g.desc }}</small>
                  </div>
                  <div class="mg-l-auto d-flex align-self-center"></div>
                </li>
              </ul>

              <p class="tx-color-02 mg-y-20 font-weight-bold">
                {{
                  isDesktop ? "Банкны апп-аар төлөх" : "Банкны апп-аар төлөх"
                }}
              </p>
            </div>

            <ul v-if="pay.mode == 1 && isDesktop" class="list-group mg-b-20">
              <!-- qPay_deeplink -->
              <li
                @click="deepAfter(p)"
                role="button"
                v-for="(p, i) in pay.qpay.urls"
                :key="i"
                class="list-group-item d-flex align-items-center"
              >
                <a @click="deepAfter(p)" role="button">
                  <img
                    :src="`${p.logo}`"
                    class="wd-30 rounded-circle mg-r-15"
                    alt=""
                  />
                </a>
                <div
                  class="text-left"
                  style="display: inline-block; width: 100%"
                >
                  <a @click="deepAfter(p)" role="button">
                    <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">
                      {{ p.name }}
                    </h6>
                    <div class="d-block tx-11 text-muted">төлбөр төлөх</div>
                  </a>
                </div>
              </li>
            </ul>
            <ul
              v-else-if="pay.mode == 1 && !isDesktop"
              class="list-group mg-b-20"
            >
              <li
                @click="gotToLink(p.link)"
                v-for="(p, i) in pay.qpay.urls"
                :key="i"
                class="list-group-item d-flex align-items-center"
              >
                <a :href="p.link">
                  <img
                    :src="`${p.logo}`"
                    class="wd-30 rounded-circle mg-r-15"
                    alt=""
                  />
                </a>
                <div
                  class="text-left"
                  style="display: inline-block; width: 100%"
                >
                  <a :href="p.link">
                    <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">
                      {{ p.name }}
                    </h6>
                    <div class="d-block tx-11 text-muted">төлбөр төлөх</div>
                  </a>
                </div>
              </li>
            </ul>
            <div v-else-if="pay.mode == 2">
              <p class="mg-b-0">Тайлбар</p>
              <input
                v-model="pay.msg"
                maxlength="450"
                class="form-control mg-y-20"
                type="text"
              />
            </div>
            <div v-else-if="pay.mode == 3" class="">
              <div class="alert alert-info mg-b-0" role="alert">
                <h6 class="alert-heading">Төлбөр төлөх</h6>
                <p>
                  Та доорхи QR кодыг интернет банкны апп-р уншуулж төлбөрөө
                  хийсний дараа ТӨЛБӨР ШАЛГАХ товчыг дарж үргэлжлүүлнэ үү.
                </p>
              </div>
              <div  class="flex" style="justify-content: center;">
              <img :src="pay.qrImg" />
              </div>
              <div class="alert alert-danger mg-b-0" role="alert">
                <h6 class="alert-heading">Төлбөрийг Qpay ашиглан төлнө үү</h6>
                <p>
                  Данснаас дансны хооронд гүйлгээ хийсэн тохиолдолд төлбөр
                  баталгаажихгүй болохыг АНХААРНА УУ!!!
                </p>
              </div>
            </div>
            <div v-else-if="pay.mode == 4">
              <p class="tx-color-03 mg-y-20">Төлбөрийн хэлбэр сонгоно уу</p>
              <ul class="list-group list-group-flush tx-13 text-left">
                <li
                  @click="changePayMode(g.id)"
                  role="button"
                  class="list-group-item d-flex pd-x-20"
                  v-for="(g, i) in gateWays"
                >
                  <div class="avatar">
                    <img :src="g.logo" class="rounded-circle" alt="" />
                  </div>
                  <div class="pd-l-10">
                    <p class="tx-medium mg-b-0">{{ g.name }}</p>
                    <small class="tx-12 tx-color-03 mg-b-0">{{ g.desc }}</small>
                  </div>
                  <div class="mg-l-auto d-flex align-self-center"></div>
                </li>
              </ul>
            </div>
            <!-- <div v-else-if="pay.mode==101" class="mg-b-20 text-left">toki tip</div> -->
            <div
              v-else-if="pay.mode == 101 || pay.mode == 102"
              class="mg-b-20 text-left"
            >
              <a :href="gateWayUrl" target="_blank">
                <div class="alert alert-info mg-b-0" role="alert">
                  <h6 style="font-weight: bold" class="alert-heading">
                    {{ pay.mode == 101 ? "Toki" : "Social pay" }}
                  </h6>
                  <p class="mb-0">
                    Төлбөрөө
                    <a :href="gateWayUrl" target="_blank">энд дарж</a> төлнө үү.
                  </p>
                  <hr />
                  <p>Төлбөрөө төлсний дараа төлбөр шалгах дарна уу.</p>
                </div>
              </a>
            </div>

            <!-- <div v-if="pay.mode != 4" class="card-footer text-center tx-13">
                    <a @click="changePayMode(4)" role="button" class="link-03">Буцах <i class="icon ion-md-arrow-down mg-l-5"></i></a>
                </div> -->
          </div>
          <div class="modal-footer">
            <button
              v-if="pay.mode != 3"
              @click="pay.modal = false"
              type="button"
              class="btn btn-secondary tx-13"
              data-dismiss="modal"
            >
              Болих
            </button>
            <button
              v-if="pay.mode == 2"
              @click="sendNotMy()"
              type="button"
              class="btn btn-success tx-13"
              data-dismiss="modal"
            >
              Илгээх
            </button>
            <!-- <button v-if="pay.mode==1 || pay.mode==3" @click="checkIsPaid()" type="button" class="btn btn-success tx-13" data-dismiss="modal">Төлбөр шалгах</button> -->
            <button
              v-if="
                pay.mode == 1 ||
                pay.mode == 3 ||
                pay.mode == 101 ||
                pay.mode == 102
              "
              @click="checkIsPaidNew()"
              type="button"
              class="btn btn-success tx-13"
              data-dismiss="modal"
            >
              Төлбөр шалгах
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="ebarimt.modal"
      class="modal fade show"
      style="display: block; padding-right: 17px"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content tx-14">
          <!-- <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">eBarimt</h6>
          </div> -->
          <div class="modal-body" style="overflow-y: auto; height: auto">
            <div>
              <h3 class="mg-b-20">Авто зам ашигласны төлбөр</h3>
              <table class="table table-sm mg-b-20">
                <tbody>
                  <tr>
                    <th scope="row" class="text-right bg-gray-100 tx-color-03">
                      Огноо
                    </th>
                    <td class="text-left">{{ ebarimt.data.date }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-right bg-gray-100 tx-color-03">
                      Дүн
                    </th>
                    <td class="text-left d-flex">
                      {{ ebarimt.data.amount }}
                      <button
                        @click="copyURL(ebarimt.data.amount)"
                        style="margin-left: auto; padding: 0 5px"
                        class="btn btn-xs btn-primary btn-uppercase"
                      >
                        хуулах
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-right bg-gray-100 tx-color-03">
                      Сугалааны дугаар
                    </th>
                    <td class="text-left d-flex">
                      {{ ebarimt.data.lottery }}
                      <button
                        @click="copyURL(ebarimt.data.lottery)"
                        style="margin-left: auto; padding: 0 5px"
                        class="btn btn-xs btn-primary btn-uppercase"
                      >
                        хуулах
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="alert alert-info mg-b-10" role="alert">
                <p>Та доорхи QR кодыг eBarimt апп-р уншуулна уу.</p>
              </div>
              <img :src="`data:image/png;base64,${ebarimt.base64}`" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="
                ebarimt.modal = false;
                loadList(2);
              "
              type="button"
              class="btn btn-secondary tx-13"
              data-dismiss="modal"
            >
              Хаах
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="ebrmtModal"
      class="modal fade show"
      style="display: block; padding-right: 17px"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title">Баримт авах хэлбэр сонгоно уу</h6>
            <button
              @click="ebrmtModal = false"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row row-xs" v-if="ebrmtMode == 0">
              <div class="col-6">
                <div class="card">
                  <div class="card-body tx-13">
                    <div>
                      <button
                        @click="
                          ebrmtMode = 1;
                          showPaymentChoosed();
                        "
                        class="btn btn-block btn-white"
                      >
                        Хувь хүн
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card">
                  <div class="card-body tx-13">
                    <div>
                      <button
                        @click="ebrmtMode = 2"
                        class="btn btn-block btn-white"
                      >
                        Байгууллага
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="ebrmtMode == 2">
              <form @submit.prevent="showPaymentBefore">
                <!-- Email input -->
                <div class="form-outline mb-4">
                  <input
                    v-model="ebrmtReg"
                    type="text"
                    id="form2Example1"
                    class="form-control"
                  />
                  <label class="form-label" for="form2Example1"
                    >Байгууллагын регистр</label
                  >
                </div>

                <div
                  @click.stop="
                    ebrmtFetched = false;
                    ebrmtReg = null;
                  "
                  v-if="ebrmtFetched"
                  class="d-flex justify-content-between text-muted pd-y-10 mg-b-10"
                >
                  {{ ebrmtFtchdName }}
                  <div class="mg-l-auto font-weight-bold text-danger">X</div>
                </div>

                <!-- Submit button -->
                <button
                  v-if="mdlInvoiceBef"
                  type="submit"
                  class="btn btn-primary btn-block mb-4"
                >
                  {{ ebrmtFetched ? "Нэхэмжлэх авах" : "Шалгах" }}
                </button>
                <button
                  v-else-if="ebrmtFetched"
                  type="submit"
                  class="btn btn-primary btn-block mb-4"
                >
                  Төлбөр төлөх
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-warning btn-block mb-4"
                >
                  Шалгах
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="ebrmtModal" class="modal-backdrop fade show"></div>
    <div v-if="pay.modal" class="modal-backdrop fade show"></div>
    <div v-if="ebarimt.modal" class="modal-backdrop fade show"></div>

    <div
      v-if="mdlInvoice"
      class="modal fade show"
      style="display: block; padding-right: 17px"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title">Нэхэмжлэх авах</h6>
          </div>
          <div
            class="modal-body"
            style="
              overflow-y: auto;
              height: auto;
              max-height: calc(100vh - 200px);
            "
          >
            <div id="prntee" class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
              <div class="row">
                <div class="col-sm-4">
                  <label
                    class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03"
                    >Нэхэмжлэгч</label
                  >
                  <h6 class="tx-15 mg-b-10">Зам тээврийн хөгжлийн төв ТӨҮГ.</h6>
                  <p class="mg-b-0">
                    16100 Улаанбаатар хот, Баянгол дүүрэг, 29 дугаар хороо
                  </p>
                  <p class="mg-b-0">Утас: 7000-2413</p>
                  <p class="mg-b-0">И-Мэйл: roadpay@rtdc.gov.mn</p>
                </div>
                <!-- col -->
                <div class="col-sm-4">
                  <label
                    class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03"
                    >Төлөгч</label
                  >
                  <p class="mg-b-0">{{ ebrmtReg }}</p>
                  <h6 class="tx-15 mg-y-10">{{ ebrmtFtchdName }}</h6>
                </div>
                <div class="col-sm-4 tx-right d-none d-md-block">
                  <label
                    class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03"
                    >Нэхэмжлэх №</label
                  >
                  <h1 class="tx-normal tx-color-04 mg-b-0 tx-spacing--2">
                    #{{ pay.packageId }}
                  </h1>
                  <p class="mg-b-0">Банкны нэр: Төрийн сан</p>
                  <p class="mg-b-0">Дансны нэр: Замын сан</p>
                  <p class="mg-b-0">Дансны дугаар: 100900028054</p>
                </div>
                <!-- col -->

                <!-- <div class="col-sm-6 col-lg-4 mg-t-40">
            <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Invoice Information</label>
            <ul class="list-unstyled lh-7">
              <li class="d-flex justify-content-between">
                <span>Invoice Number</span>
                <span>DF032AZ00022</span>
              </li>
              <li class="d-flex justify-content-between">
                <span>Product ID</span>
                <span>32334300</span>
              </li>
              <li class="d-flex justify-content-between">
                <span>Issue Date</span>
                <span>January 20, 2019</span>
              </li>
              <li class="d-flex justify-content-between">
                <span>Due Date</span>
                <span>April 21, 2019</span>
              </li>
            </ul>
          </div> -->
              </div>
              <!-- row -->

              <div class="table-responsive mg-t-40">
                <table class="table table-invoice bd-b">
                  <thead>
                    <tr>
                      <th class="wd-20p">Утга</th>
                      <th class="wd-40p d-none d-sm-table-cell">Огноо</th>
                      <th class="tx-center">Тоо/Ш</th>
                      <th class="tx-right">Нэгж үнэ</th>
                      <th class="tx-right">Нийт үнэ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(l, i) in list" :key="i">
                      <td class="tx-nowrap text-uppercase">
                        Зам ашигласны төлбөр {{ l.gateName }} ({{
                          $route.params.plateNo
                        }})
                      </td>
                      <td class="d-none d-sm-table-cell tx-color-03">
                        {{ moment(l.date).format("YYYY.MM.DD") }}
                        <sup>{{ moment(l.date).format("HH:mm") }}</sup>
                      </td>
                      <td class="tx-center">1</td>
                      <td class="tx-right">{{ formatPrice(l.budget) }}</td>
                      <td class="tx-right">{{ formatPrice(l.budget) }}</td>
                    </tr>

                    <!-- <tr v-else v-for="(l, i) in list">
                                <td>
                                    <div class="text-muted tx-12">{{l.gateName}}</div>
                                    <div>
                                        {{moment(l.date).format('YYYY.MM.DD')}}
                                        <sup>{{moment(l.date).format('HH:mm')}}</sup>
                                    </div>
                                </td>
                                <td>{{formatPrice(l.budget)}}</td>
                                <td class="text-right">
                                    <button @click="showDetail(l)" class="btn btn-xs btn-info">харах</button>
                                </td>
                            </tr> -->
                  </tbody>
                </table>
              </div>

              <div class="row justify-content-between">
                <div
                  class="col-sm-6 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0"
                >
                  <!-- <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Санамж</label>
            <p>Нэхэмжлэхийг хэвлэж аваад дансаар төлсөн тохиолдолд ebarimt automataar hasagdahgui tul ЗТХТ-д хандан шийдвэрлүүлнэ үү</p> -->
                </div>
                <!-- col -->
                <div class="col-sm-6 col-lg-4 order-1 order-sm-0">
                  <ul class="list-unstyled lh-7 pd-r-10">
                    <li class="d-flex justify-content-between">
                      <span>Дүн</span>
                      <span>{{
                        formatPrice(
                          list.reduce((sum, { budget }) => sum + budget, 0)
                        )
                      }}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <span>НӨАТ</span>
                      <span>0</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Нийт дүн</strong>
                      <strong>{{
                        formatPrice(
                          list.reduce((sum, { budget }) => sum + budget, 0)
                        )
                      }}</strong>
                    </li>
                  </ul>
                </div>
                <!-- col -->
              </div>
              <!-- row -->
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="
                mdlInvoice = false;
                showPayment();
              "
              class="btn btn btn-success"
            >
              Төлөх
            </button>
            <button @click="printElem()" class="btn btn btn-primary mg-l-auto">
              Хэвлэх
            </button>
            <button
              @click="mdlInvoice = false"
              type="button"
              class="btn btn-secondary tx-13"
              data-dismiss="modal"
            >
              Хаах
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mdlInvoice" class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
import Vue from "vue";
import { request } from "@/api/all";
import mainURL from "../../../config/mainUrl";
import { mapActions } from "vuex";
import { getToken } from "@/libs/util";

import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      gateWays: [
        // { id: 1, name: 'Q Pay', desc: 'Q Pay ашиглан төлөх', logo:'qpay.png' },
        {
          id: 102,
          name: "Голомт банк, Social pay",
          desc: "Social pay ашиглан төлөх",
          logo: "golomt.png",
        },
        { id: 101, name: "TokiPay", desc: "Төлбөр төлөх", logo: "toki.png" },
      ],

      gateWayUrl: "",

      paidInvoiceCount: 0,

      mdlInvoiceBef: false,
      mdlInvoice: false,

      ebrmtModal: false,
      ebrmtMode: 0,
      ebrmtFtchdName: null,
      ebrmtFetched: false,
      ebrmtReg: null,
      infVehicle: {
        color: null,
        industry: null,
        model: null,
      },
      mode: 1,
      isDesktop: false,
      list: [],

      pay: {
        modal: false,
        msg: "",
        mode: 1,
        qrImg: "",
        qpay: {},
        packageId: null,
      },

      ebarimt: {
        modal: false,
        base64: null,
        data: {},
      },
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.loadList(1);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    gotToLink(link) {
      window.open(link, "_self");
    },
    changePayMode(m) {
      if (m == 4 || m == 1) {
        this.pay.mode = m;
      } else if (m == 102) {
        this.handleLoading({ isLoading: true });
        request({
          api: { method: "GET", url: "roadPay/PayByBadge" },
          params: {
            mode: 2,
            id: null,
            plateNo: this.$route.params.plateNo,
            flag: this.ebrmtMode == 1 ? 0 : 1,
            regNo: this.ebrmtReg,
            paymentMethod: 1,
          },
          store: this.$store,
        }).then((res) => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            //
            window.open(
              `https://ecommerce.golomtbank.com/payment/mn/${res.data.golomt.invoice}`,
              "_self"
            );
            //this.gateWayUrl= `https://ecommerce.golomtbank.com/payment/mn/${res.data.golomt.invoice}`;
            //this.pay.mode= 102;
            this.pay.packageId = res.data.packageId;
          }
        });
      } else if (m == 101) {
        if (this.isDesktop) {
          alert("Гар утаснаас төлбөр төлөх боломжтой");
        } else {
          //this.pay.mode= 101;
          this.handleLoading({ isLoading: true });
          request({
            api: { method: "GET", url: "roadPay/PayByBadge" },
            params: {
              mode: 2,
              id: null,
              plateNo: this.$route.params.plateNo,
              flag: this.ebrmtMode == 1 ? 0 : 1,
              regNo: this.ebrmtReg,
              paymentMethod: 2,
            },
            store: this.$store,
          }).then((res) => {
            this.handleLoading({ isLoading: false });
            console.log("res", res);
            if (res !== false) {
              //console.log('res', res);
              window.open(res.data.toki.data.deeplink, "_self");
              //this.gateWayUrl= res.data.toki.data.deeplink;
              //window.open(res.data.data.deepLink);
              //this.pay.mode= 101;
              this.pay.packageId = res.data.packageId;
            }
          });
        }
      }

      //https://roadpay.mn/core/roadPay/PayByBadge?mode=2&plateNo=6963УНС&paymentMethod=2&flag=0
      //https://ecommerce.golomtbank.com/payment/mn/
    },
    printElem() {
      this.$htmlToPaper("prntee", () => {});
    },
    moment,
    ...mapActions(["handleLoading"]),
    copyURL(mytext) {
      mytext = mytext.replace(/ /g, "");
      var input = document.createElement("input");
      input.setAttribute("value", mytext);
      input.value = mytext;
      document.body.appendChild(input);
      try {
        input.select();
        input.click();
        input.focus();
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        //console.log('Testing code was copied ' + successful + ' ' + msg);
      } catch (err) {
        //console.log('Oops, unable to copy');
      }
      document.body.removeChild(input);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onResize() {
      this.isDesktop = window.innerWidth >= 600;
    },
    getEbarimt(l) {
      // if(l.ebarimtData==null || l.ebarimtData==""){
      // }else{
      //     this.handleLoading({ isLoading: true });
      //     request({
      //         api: { method: 'POST', url: 'QR/Generate'},
      //         data:{qr: JSON.parse(l.ebarimtData)['qrData']},
      //         store: this.$store
      //     }).then(res => {
      //         this.handleLoading({ isLoading: false });
      //         if (res !== false) {
      //             this.ebarimt.data= l;
      //             this.ebarimt.base64= res.data.base64;
      //             this.ebarimt.modal= true;
      //             console.log(res);
      //         }
      //     });
      // }
    },
    loadInvoices() {
      if (this.paidInvoiceCount > 0) {
        this.handleLoading({ isLoading: true });
        request({
          api: { method: "GET", url: "RoadPay/GetPayedInvoices" },
          //params:{plateNo: this.$route.params.plateNo},
          store: this.$store,
        }).then((res) => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            this.mode = 3;
            this.list = res.data.list;
            console.log(res);
          }
        });
      } else {
        alert("Төлөгдсөн нэхэмжлэх үүсээгүй");
      }
    },
    loadList(mode) {
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "GET", url: "RoadPay/GetList" },
        params: { plateNo: this.$route.params.plateNo },
        store: this.$store,
      }).then((res) => {
        this.handleLoading({ isLoading: false });
        if (res !== false) {
          //console.log('res=> ', res);

          if (res.data.list.length == 0) {
            alert(
              this.$route.params.plateNo +
                " дугаартай тээврийн хэрэгсэлд Цахим ТАЦ-р нэвтэрсэн мэдээлэл олдсонгүй"
            );
            this.$router.push("/");
          } else {
            this.paidInvoiceCount = res.data.paidInvoiceCount;
            this.infVehicle = res.data.vehicle;
            if (mode == 1) {
              //this.list= res.data.list.filter(x=> x.paid == false );
              this.list = _.orderBy(
                res.data.list.filter((x) => x.paid == false),
                ["id"],
                ["desc"]
              );
            } else {
              //this.list= res.data.list.filter(x=> x.paid == true );
              this.list = _.orderBy(
                res.data.list.filter((x) => x.paid == true),
                ["id"],
                ["desc"]
              );
            }
            //console.log("combo ", this.list.length, res.data.list.length);
            this.mode = mode;
          }
          if (this.$route.params.pay != undefined) {
            if (this.$route.params.pay == 1) {
              this.showPayment();
            } else if (this.$route.params.pay == 31) {
              alert("Төлбөр төлөлт амжилтгүй. Дахин оролдоно уу");
            } else if (this.$route.params.pay == 33) {
              alert("Төлбөр амжилттай төлөгдлөө");
              this.checkIsPaidNew();
            }
          }
        } else {
          this.$router.push("/");
        }
      });
    },
    showDetail(item) {
      this.$router.push("/d/" + item.id);
    },
    deepAfter(p) {
      //this.pay.qrImg= 'data:image/png;base64,'+this.pnlt.qpay.qPay_QRimage;
      this.pay.qrImg = "data:image/png;base64," + this.pay.qpay.qr_image;
      this.pay.mode = 3;
    },
    showInvoice() {
      this.mdlInvoiceBef = true;
      this.showPayment();
      //this.mdlInvoice= true;
      // this.ebrmtMode= 0;
      // this.ebrmtReg= null;
      // this.ebrmtModal= true;
    },
    showPayment() {
      this.ebrmtFetched = false;
      this.ebrmtMode = 0;
      this.ebrmtReg = null;
      this.ebrmtModal = true;
    },
    showPaymentBefore() {
      if (this.ebrmtReg != null && this.ebrmtReg != "" && this.ebrmtMode == 2) {
        if (this.ebrmtFetched) {
          this.showPaymentChoosed();
        } else {
          this.handleLoading({ isLoading: true });
          request({
            api: { method: "GET", url: "CRM/GetOrganizationNameByRegister" },
            params: { regNo: this.ebrmtReg },
            store: this.$store,
          }).then((res) => {
            this.handleLoading({ isLoading: false });
            if (res !== false) {
              console.log("GetOrganizationNameByRegister=>", res);
              if (res.data.name != null && res.data.name != "") {
                this.ebrmtFtchdName = res.data.name;
                this.ebrmtFetched = true;
              } else {
                alert("Байгууллагын мэдээлэл олдсонгүй");
              }
            }
          });
        }
      } else {
        alert("Байгууллагын регистер оруулна уу !");
      }
    },
    showPaymentChoosed() {
      this.ebrmtModal = false;

      this.handleLoading({ isLoading: true });
      request({
        api: { method: "GET", url: "roadPay/PayByBadge" },
        params: {
          mode: 2,
          id: null,
          plateNo: this.$route.params.plateNo,
          flag: this.ebrmtMode == 1 ? 0 : 1,
          regNo: this.ebrmtReg,
        },
        store: this.$store,
      }).then((res) => {
        this.handleLoading({ isLoading: false });
        if (res !== false) {
          this.pay.qpay = res.data.qpay;
          this.pay.packageId = res.data.packageId;

          if (this.mdlInvoiceBef == true) {
            this.mdlInvoice = true;
          } else {
            this.pay.modal = true;
            this.pay.mode = 1;
            //this.pay.mode=4;
          }

          // if(this.isDesktop){
          //     this.pay.mode= 3;
          // }else{
          //     this.pay.mode= 1;
          // }

          //console.log('pay res', res);
        }
      });
    },

    checkIsPaidNew(l) {
      let _pkid = this.pay.packageId;
      if (l != undefined) {
        _pkid = l.qpayPackageId;
        //console.log(l.qpayPackageId);
      }
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "GET", url: "Qpay/CheckInvoiceByPackageId" },
        params: {
          id: _pkid,
          regNo: this.ebrmtReg,
        },
        store: this.$store,
      }).then((res) => {
        this.handleLoading({ isLoading: false });
        if (res !== false) {
          if (res.data.ebarimt != undefined && res.data.ebarimt != null) {
            this.ebarimt.data = res.data.ebarimt;
            this.ebarimt.base64 = res.data.base64;
            this.ebarimt.modal = true;
          } else {
            this.loadList(2);
          }
          this.pay.modal = false;
          //console.log('res', res);
        }
      });
      //window.location.reload(true);
    },
  },
};
</script>
<style scoped>
@import "https://res.gosmart.mn/static/df/assets/css/dashforge.css";
</style>
